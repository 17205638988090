/* RESPONSIBLE TEAM: team-ai-agent-2 */

export interface CustomAnswerWireFormat {
  id: number;
  title: string;
}

export class CustomAnswer {
  constructor(
    public id: number,
    public title: string,
  ) {
    this.id = id;
    this.title = title;
  }

  static deserialize(data: CustomAnswerWireFormat): CustomAnswer {
    return new CustomAnswer(data.id, data.title);
  }
}
